import React, { useState } from 'react'
import { Card, Form, Spinner } from 'react-bootstrap'
import { fetchVertederos } from '../api/fetchVertederos'
import ErrorMessage from './ErrorMessage'

const VertederoItem = ({ item, setChangesMade }) => {
    const [vertederos, setVertederos] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const [newName, setNewName] = useState(item.client_name);

    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false)

    const handleError = (error) => {
        if (error.message) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage("Ocurrió un error al procesar la solicitud. Por favor, intente más tarde.");
        }
        setShowError(true);
    };


    const handleFetchVertederos = () => {
        setIsFetching(true);

        fetchVertederos()
            .then((fetchedData) => {
                setVertederos(fetchedData);
            })
            .catch((error) => {
                console.log("Error occurred while fetching stations:", error.message);
                handleError(error);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const handleChangeVertedero = (event) => {
        setNewName(event.target.value);
        item.new_name = event.target.value;
        setChangesMade(true);
    };

    return (
        <Card
            style={{
                padding: '0px',
                border: '0px',
                userSelect: "none",
                marginTop: '-8px',
                marginBottom: '0px'
            }}
            className="draggable-item"
        >
            <Card.Body
                style={{ padding: '0px' }}
            >
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                        margin: 0,
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                        overflow: 'hidden',
                    }}
                >
                    <Form>
                        <Form.Select
                            aria-label="Select operation"
                            size="sm"
                            className='draggable-item'
                            style={{
                                margin: 0,
                                border: "0px",
                                padding: "0px 0px 0px 10px",
                                width: '198px',
                                height: '44px',
                                fontSize: '14px',
                            }}
                            onFocus={handleFetchVertederos}
                            onChange={handleChangeVertedero}
                        >
                            {isFetching ? (
                                <Spinner />
                            ) : (
                                <>
                                    <option value={item.client_name}>{item.client_name}</option>
                                    {vertederos.map((vertedero) => {
                                        return (
                                            <option key={vertedero[4]} value={vertedero[4]}>{vertedero[4]}</option>
                                        )
                                    })}
                                </>
                            )}

                        </Form.Select>
                    </Form>
                </div>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between" style={{ padding: '0px' }}>
                <small>Llegada: {item.arrival_time}</small>
                <span>
                    {(newName !== item.client_name) ? (
                        <span style={{ color: 'red' }}>*</span>
                    ) : (
                        <></>
                    )}
                    <strong> {item.operation_type.charAt(0).toUpperCase()}</strong>
                </span>
            </Card.Footer>
            <ErrorMessage show={showError} onHide={() => setShowError(false)} errorMessage={errorMessage} />
        </Card >
    )
}

export default VertederoItem