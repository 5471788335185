import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const SelectDriver = ({ drivers, index, setDrivers }) => {
    const [availableDrivers, setAvailableDrivers] = useState([]);

    useEffect(() => {
        setAvailableDrivers(drivers);
    }, [drivers]);

    const handleSelectDriver = (event) => {
        const selectedDriverId = parseInt(event.target.value);
        setDrivers(prevDrivers => {
            const newDrivers = [...prevDrivers];

            // Find the index of the currently selected driver in the list being changed
            const currentIndex = newDrivers.findIndex((driver, i) => i !== index && driver.id === selectedDriverId);

            // Swap the drivers only if the selected driver is different from the current driver in the list
            if (currentIndex !== -1 && currentIndex !== index) {
                const temp = newDrivers[index];
                newDrivers[index] = newDrivers[currentIndex];
                newDrivers[currentIndex] = temp;
            }

            return newDrivers;
        });
    };

    return (
        <Form.Select
            aria-label="Selecciona una ubicación"
            className='form-control mb-2'
            value={drivers[index]?.id || ''}
            onChange={handleSelectDriver}
            required
        >
            {availableDrivers.map(driver => (
                <option key={driver.id} value={driver.id}>{driver.name}</option>
            ))}
        </Form.Select>
    );
};

export default SelectDriver;
