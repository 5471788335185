import React, { useState } from 'react';
import { Card, Row, Col, Collapse } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';


const TotalCost = ({ route_cost, delta, cost_by_distance, cost_by_duration }) => {
    const [open, setOpen] = useState(false);

    return (
        <Card className="mb-2" style={{ backgroundColor: '#aaaaaa', borderColor: '#1D3557' }} onClick={() => setOpen(!open)}>
            <Card.Body style={{ padding: '5px 10px' }}>
                <Card.Title as="h6">
                    <Row className="align-items-center">
                        <Col className="d-flex align-items-center">
                            Costo:
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            {open ? (
                                <ChevronUp style={{ fontWeight: 'bold' }} /> // Or use a bold icon variant if available
                            ) : (
                                <ChevronDown style={{ fontWeight: 'bold' }} /> // Or use a bold icon variant if available
                            )}
                        </Col>
                    </Row>
                </Card.Title>
                <Card.Text as="div">
                    <Row>
                        <Col>
                            <span>$ {Number(route_cost).toFixed(1)}</span>
                        </Col>
                        <Col>
                            {delta.value !== null && (
                                <small className="ms-2" style={{ color: delta.value <= 0 ? 'green' : 'red' }}>
                                    ({delta.formatted})
                                </small>
                            )}
                        </Col>
                    </Row>
                </Card.Text>
                <Collapse in={open}>
                    <div id="collapse-cost-details">
                        <Row>
                            <Col>
                                <strong>Por distancia:</strong> ${Number(cost_by_distance).toFixed(0)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong>Por tiempo: </strong> ${Number(cost_by_duration).toFixed(0)}
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </Card.Body>
        </Card>
    );
}

export default TotalCost;
