import React from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'

const SubmitModal = ({ showModal, handleCloseModal, errorMessage, handleConfirmSubmit, isSubmitted, isSubmitting }) => {
    return (
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSubmitting ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                        </Spinner>
                        <p>Enviando información a Odoo...</p>
                    </div>
                ) : isSubmitted ? (
                    <Alert variant="success">Información enviada exitosamente.</Alert>
                ) : errorMessage ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                ) : (
                    '¿Está seguro de enviar los datos?'
                )}
            </Modal.Body>
            <Modal.Footer>
                {(!isSubmitting && !isSubmitted) ? (
                    <>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleConfirmSubmit}>
                            Confirmar
                        </Button>
                    </>
                ) : (!isSubmitting && isSubmitted) ? (
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                ) : (
                    <></>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default SubmitModal