import { Draggable } from "@hello-pangea/dnd";
import React, { useEffect, useRef, useState } from "react";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import '../pages/styles/Home.css'
import VertederoItem from "./VertederoItem";

const DraggableItem = ({ item, index, nextItem, setChangesMade }) => {
    const [placement, setPlacement] = useState('right');
    const cardRef = useRef(null);

    useEffect(() => {
        const calculatePlacement = () => {
            if (cardRef.current) {
                const { right } = cardRef.current.getBoundingClientRect();
                const windowWidth = window.innerWidth;
                if (right > windowWidth / 2) {
                    setPlacement('left');
                } else {
                    setPlacement('right');
                }
            }
        };

        calculatePlacement();
        window.addEventListener('resize', calculatePlacement);

        return () => {
            window.removeEventListener('resize', calculatePlacement);
        };
    }, []);

    const renderPopover = (props) => (
        <Popover id={`popover-main-${item.station_id}`} {...props}>
            <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>Estación {item.client_name}</Popover.Header>
            <Popover.Body className="p-3">
                <p><strong>Dirección: </strong>{item.contact_address_complete}</p>
                <p><strong>Operación: </strong>{item.operation_type}</p>
                <p className="mb-0"><strong>Coordenadas: </strong>{item.location}</p>
            </Popover.Body>
        </Popover>
    );

    const renderNextItemPopover = (props) => (
        nextItem ? (
            <Popover id={`popover-vertedero-${nextItem.station_id}`} {...props}>
                <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>Estación {nextItem.client_name}</Popover.Header>
                <Popover.Body className="p-3">
                    <p><strong>Dirección: </strong>{nextItem.contact_address_complete}</p>
                    <p><strong>Operación: </strong>{nextItem.operation_type}</p>
                    <p className="mb-0"><strong>Coordenadas: </strong>{nextItem.location}</p>
                </Popover.Body>
            </Popover>
        ) : null
    );

    return (
        <Draggable
            key={item.task_id}
            draggableId={String(item.task_id)}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        opacity: snapshot.isDragging ? 0.8 : 1,
                        ...provided.draggableProps.style,
                    }}
                >
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement={placement}
                        overlay={renderPopover}
                        delay={500}
                    >
                        <Card className="draggable-item" ref={cardRef} style={{ cursor: 'pointer', padding: '12px 10px' }}>
                            <Card.Body style={{ padding: '0px' }}>
                                <Card.Text
                                    style={{
                                        whiteSpace: 'normal',
                                        fontWeight: 'bold',
                                        paddingBottom: '8px',
                                    }}
                                >
                                    {item.client_name}
                                </Card.Text>
                                <Card.Footer className="d-flex justify-content-between" style={{ padding: '5px 0' }}>
                                    <small>Llegada: {item.arrival_time}</small>
                                    <span><strong>{item.operation_type.charAt(0).toUpperCase()}</strong></span>
                                </Card.Footer>

                                {/* If there's a nextItem (Vertedero), render it */}
                                {nextItem && (
                                    <>
                                        <hr style={{ margin: '8px 0' }} />
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="bottom"
                                            overlay={renderNextItemPopover}
                                            delay={500}
                                        >
                                            <div>
                                                <VertederoItem
                                                    setChangesMade={setChangesMade}
                                                    item={nextItem}
                                                    style={{
                                                        whiteSpace: 'normal',
                                                    }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </OverlayTrigger>
                </div>
            )}
        </Draggable>
    );
};

export default DraggableItem;