import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, ListGroup, Button, Row, Col } from 'react-bootstrap';

const History = () => {
  const location = useLocation();
  const history = location.state?.history || [];
  const [expandedIteration, setExpandedIteration] = useState(null);
  const navigate = useNavigate();

  const handleCardClick = (index) => {
    setExpandedIteration(expandedIteration === index ? null : index);
  };

  const handleBackButton = () => {
    navigate('/home')
  }

  return (
    <Container>
      <Row className="align-items-center pb-4 pt-4">
        <Col xs={12} md={6}>
          <h1>Historial</h1>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end">
          <Button onClick={handleBackButton}>Volver</Button>
        </Col>
      </Row>

      {history.length === 0 ? (
        <p>Sin historial en esta sesión.</p>
      ) : (
        history.map((entry, index) => (
          <Card key={index} className="mb-3" onClick={() => handleCardClick(index)}>
            <Card.Body>
              <Card.Title>
                <Row className='justify-content-between'>
                  <Col>
                    Iteración {index + 1}
                  </Col>
                  <Col className="text-end pe-4">
                    <Button>Restaurar</Button>
                  </Col>
                </Row>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{entry.timestamp.toLocaleString()}</Card.Subtitle>
              <Card.Text>
                <strong>Costo:</strong> ${entry.data.total_cost} <br />
                <strong>Por distancia:</strong> ${entry.data.total_cost_per_km} <br />
                <strong>Por duración:</strong> ${entry.data.total_cost_per_hour}
              </Card.Text>
              {expandedIteration === index && (
                <ListGroup>
                  {entry.data.stations.map((station, idx) => (
                    <ListGroup.Item key={idx}>
                      <strong>Vehículo {station.vehicle_id}</strong>:
                      <ul>
                        {station.itinerary.map((stop, stopIdx) => (
                          <li key={stopIdx}>
                            {stop.client_name} ({stop.location}) - Llegada: {stop.arrival_time}, Salida: {stop.departure_time}
                          </li>
                        ))}
                      </ul>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        ))
      )}
    </Container>
  );
};

export default History;
