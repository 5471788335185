export const postRemodel = async (vertedero_parameter, standby_tasks, drivers, available_containers) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/remodel_route`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "vertedero_parameter": vertedero_parameter,
                "standby_tasks": standby_tasks,
                "drivers": drivers,
                available_containers
            })
        });

        if (!response.ok) {
            // If response is not okay, fetch the error details
            const errorDetails = await response.json(); // Assuming the backend returns JSON for errors
            const errorMessage = errorDetails.message || `Error: ${response.statusText}`;

            // Throw a new error with the error message from the response
            throw new Error(errorMessage);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error occurred:", error.message); // Log the error message

        if (error.message) {
            throw new Error(error.message);
        } else {
            throw new Error("Error inesperado")
        }
    }
};
