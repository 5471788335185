import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal, Button, Spinner } from 'react-bootstrap';
import "./styles/Login.css"
import img_logo from "../imgs/logo_azul_colored.png"
import { login } from '../api/login';

function Login() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSpinner(true);

        try {
            const response = await login(formData);

            setSpinner(false);
            if (response.token) {
                localStorage.setItem('authToken', response.token);

                navigate('/home');
            } else {
                setErrorMessage(response.message || "Usuario no autorizado");
                setShowErrorModal(true);
            }
        } catch (error) {
            setSpinner(false);
            setErrorMessage("Nombre de usuario o contraseña incorrecto");
            setShowErrorModal(true);
            console.error("Login error:", error);
        }
    };

    const handleClose = () => {
        setShowErrorModal(false);
        setErrorMessage("");
    };

    return (
        <Container className='login-container'>
            <form onSubmit={handleSubmit}>
                <div className='img-logo-login'>
                    <a target="_blank" rel="noopener noreferrer">
                        <img src={img_logo} alt='Logo Azul' />
                    </a>
                </div>
                <div className="form-group">
                    <label htmlFor="username" className="pb-2">Nombre de Usuario</label>
                    <input
                        name="username"
                        type="text"
                        className="form-control"
                        id="username"
                        value={formData.username}
                        onChange={(e) => handleFormChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password" className="pb-2 pt-4">Contraseña</label>
                    <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password"
                        value={formData.password}
                        onChange={(e) => handleFormChange(e)}
                        required
                    />
                </div>

                <button type="submit" className="btn btn-primary btn-login" disabled={spinner}>
                    {spinner ? <Spinner as="span" animation="border" size="sm" /> : 'Iniciar Sesión'}
                </button>
            </form>

            <Modal show={showErrorModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error de Inicio de Sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Login;