import React, { useEffect, useRef, useState } from 'react'
import { Card, OverlayTrigger, Popover } from 'react-bootstrap'

const UndraggableItem = ({ item }) => {
    const [placement, setPlacement] = useState('right');
    const cardRef = useRef(null);

    useEffect(() => {
        const calculatePlacement = () => {
            if (cardRef.current) {
                const { right } = cardRef.current.getBoundingClientRect();
                const windowWidth = window.innerWidth;
                if (right > windowWidth / 2) {
                    setPlacement('left');
                } else {
                    setPlacement('right');
                }
            }
        };

        calculatePlacement();
        window.addEventListener('resize', calculatePlacement);

        return () => {
            window.removeEventListener('resize', calculatePlacement);
        };
    }, []);

    const renderPopover = (props) => (
        <Popover id={item.station_id} {...props}>
            <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>Estación {item.client_name}</Popover.Header>
            <Popover.Body className="p-3">
                <p>
                    <strong>Dirección: </strong>{item.address}
                </p>
                <p>
                    <strong>Operación: </strong>{item.operation_type}
                </p>
                <p className="mb-0">
                    <strong>Coordenadas: </strong>{item.location}
                </p>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 1000, hide: 400 }}
            overlay={renderPopover}
        >
            <Card
                bg={"secondary"}
                style={{
                    userSelect: "none",
                    marginBottom: "8px",
                }}>
                <Card.Body>
                    <Card.Text className='mb-0'>{item.client_name}</Card.Text>
                    <Card.Text><small>Salida: {item.departure_time}</small></Card.Text>
                </Card.Body>
            </Card>
        </OverlayTrigger>
    )
}

export default UndraggableItem